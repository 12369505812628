import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import CallToAction from "../../components/section/CallToAction/callToAction"

const EmailMarketingPage = () => {
    const data = useStaticQuery(graphql`
    query {
      emailMarketingImage1: file(relativePath: { eq: "images/sections/marketing-digital/email-marketing/por-que-invertir-en-email-marketing-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      emailMarketingImage2: file(relativePath: { eq: "images/sections/marketing-digital/email-marketing/ibx-agency-creacion-emailings-newsletter-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      emailMarketingImage3: file(relativePath: { eq: "images/sections/marketing-digital/email-marketing/ventajas-emailing-marketing-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Agencia Email Marketing"
                description="Agencia de Marketing Digital en Barcelona experta en el diseño, programación, envío y monitorización de email marketing: mailings y newsletter."
            />

            <BannerPage
                title="El éxito está en tu lista de contactos"
                text="Refuerza la imagen de tu marca y capta nuevos clientes"
                color="green"
            />

            <SectionTextImage
                title1="¿Por qué invertir "
                title2="en email marketing?"
                text={<Fragment>
                    Actualmente, el <strong>e-marketing</strong> es una de las actividades más utilizadas en Internet. Es un servicio personalizado que consiste en el envío masivo de emails a los diferentes contactos de tu <strong>base de datos</strong>, tu lista. El objetivo es contactar con tu público objetivo a través de unas técnicas efectivas.<br /><br />
                    Hay que tener en cuenta que el <strong>correo electrónico</strong> es uno de los sistemas de comunicación más veteranos de <strong>Internet</strong>. Es por ello, que el e-marketing es una herramienta muy potente y consolidada que incluye <strong>newsletters y mailings</strong>.
                    </Fragment>}
                image={data.emailMarketingImage1.childImageSharp.fluid}
                imageAlt="¿Por qué invertir en email marketing? - Agencia Email Marketing"
                color="green"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="En IBX creamos "
                title2="tus campañas"
                text={<Fragment>
                    En IBX somos expertos en el diseño y envío de mailings y newsletter. <br /><br />
                    <ul class="text-left">
                        <li>Diseñamos el <strong>cuerpo y mensaje</strong> de tu comunicación</li>
                        <li>Creamos y seleccionamos una <strong>base de datos</strong></li>
                        <li>Realizamos el <strong>envío</strong> personalizado</li>
                        <li><strong>Monitorizamos</strong> los resultados</li>
                        <li><strong>Analizamos</strong> el impacto que han tenido los envíos</li>
                        <li><strong>Depuramos</strong> la base de datos</li>
                    </ul>
                </Fragment>}
                image={data.emailMarketingImage2.childImageSharp.fluid}
                imageAlt="Campañas emarketing - Agencia Email Marketing"
                color="green"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="Ventajas del "
                title2="email marketing"
                text={<Fragment>
                    <ul class="text-left">
                        <li><strong>Costes</strong> de envío <strong>bajos</strong></li>
                        <li>Personalización de <strong>emails</strong></li>
                        <li>Campañas con <strong>grandes resultados</strong></li>
                        <li><strong>Captación</strong> de potenciales clientes</li>
                        <li><strong>Fidelización</strong> de clientes</li>
                        <li><strong>Consolidación</strong> la imagen de la marca</li>
                        <li>Creación de lista de contactos real</li>
                    </ul>
                </Fragment>}
                image={data.emailMarketingImage3.childImageSharp.fluid}
                imageAlt="Ventajas del email marketing - Agencia Email Marketing"
                color="green"
                containerTextRight={false}
            />

            <CallToAction
                color="green"
                title="¿Quieres informar de las novedades o promociones de tu empresa?"
                text={<Fragment>
                    ¡Somos la <strong>agencia de email marketing en Barcelona</strong> que necesitas! <br />
                    ¡Crear vínculos y fideliza tus clientes!
                    </Fragment>}
                button="¡Empieza ahora!"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default EmailMarketingPage
